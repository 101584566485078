import Axios from 'axios';

export const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // timeout: 5000
  headers: {
    'X-API-KEY': process.env.VUE_APP_API_KEY,
  },
});

export const config = (option = {}) => {
  return {
    headers: {
      'X-API-KEY': process.env.VUE_APP_API_KEY,
    },
    ...option,
  };
};
