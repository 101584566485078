<template>
  <BaseModalVue @handleCloseModal="handleCloseModal">
    <div class="relative p-4 w-full h-full grid justify-items-center content-center pb-20 font-Hanuman">
      <div class="relative w-full bg-white rounded-lg shadow content-center">
        <ButtonCloseVue @handleCloseModal="handleCloseModal" />
        <div class="p-4 text-center">
          <div class="-mt-16 mb-4">
            <img src="../assets/cat-sad.png" class="mx-auto w-[6rem]" />
          </div>
          <p class="font-bold text-xl mb-4">
            {{ $t('tran22') }}
          </p>
          <p class="text-base">
            {{ $t('tran23') }}
          </p>
          <div class="pt-6">
            <button
              class="w-full text-black bg-[#00D8D6] hover:bg-amber-500 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-md px-5 py-2.5 text-center"
              @click="handleCloseModal">
              {{ $t('tran24') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </BaseModalVue>
</template>

<script>
import { defineComponent } from 'vue';
import BaseModalVue from './BaseModal.vue';
import ButtonCloseVue from './ButtonClose.vue';

export default defineComponent({
  components: {
    BaseModalVue,
    ButtonCloseVue,
    // <ButtonCloseVue @handleCloseModal="handleCloseModal" />
  },
  methods: {
    handleCloseModal() {
      this.$router.push({ hash: "?detect-onpopstate=true" });
      this.$emit('handleCloseModal');
    },
  },
});
</script>
