<template>
  <div tabindex="-1" aria-hidden="true"
    class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full bg-black/70 font-Hanuman">
    <!-- <ButtonCloseVue @handleCloseModal="handleCloseModal" /> -->
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
// import ButtonCloseVue from './ButtonClose.vue';
export default defineComponent({
  components: {
    // ButtonCloseVue,
  },
  methods: {
    handleCloseModal() {
      this.$emit('handleCloseModal');
    },
  },
});
</script>
