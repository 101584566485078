import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import './index.css';
import VueBridgeGateway from 'vue-bridge-gateway';
import { createI18n } from 'vue-i18n';
import { defaultLocale, messages } from './i18n';
import * as config from './const/config';
import * as util from './utils/helper.util';

const i18n = createI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
});

const app = createApp(App);

app.config.globalProperties.$config = config;
app.config.globalProperties.$util = util;

app
  .use(router)
  .use(i18n)
  .use(VueBridgeGateway, { debug: true, delay: 200 })
  .mount('#app');
