<template>
  <BaseModalVue @handleCloseModal="handleCloseModal">
    <div
      class="relative p-4 w-full font-Hanuman max-w-md h-full md:h-auto grid justify-items-center content-center"
    >
      <div class="relative bg-white w-full rounded-lg shadow">
        <div class="py-6 px-6 lg:px-8">
          <div class="-mt-16 mb-10">
            <img src="../assets/cat-smile.png" class="mx-auto w-[6rem]" />
          </div>

          <h2 class="text-center mb-5">{{ $t('tran32') }}</h2>

          <div class="grid grid-cols-2 gap-4">
            <div>
              <button
                class="w-full text-black border hover:bg-gray-500 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-md px-5 py-2.5 text-center"
                @click="handleExit"
              >
                {{ $t('tran33') }}
              </button>
            </div>
            <div>
              <button
                class="w-full text-black border border-[#FECF2A] bg-[#FECF2A] hover:bg-amber-500 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-md px-5 py-2.5 text-center"
                @click="handleCloseModal"
              >
                {{ $t('tran34') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseModalVue>
</template>

<script>
import { defineComponent } from 'vue';
import { globalState } from '../store';
import BaseModalVue from './BaseModal.vue';
export default defineComponent({
  components: {
    BaseModalVue,
  },
  data: function () {
    return { globalState };
  },
  methods: {
    handleCloseModal() {
      this.$router.push({ hash:  "?detect-onpopstate=true" });
      this.globalState.setVisibleExit(false);
    },
    handleExit: function () {
      this.$emit('handleExit');
    },
  },
});
</script>
