<template>
  <LayoutVue :isHideText="isHideText" syle="font-family: 'Inter', sans-serif, 'Hanuman', serif;">
    <h1 class="text-[#013795] text-2xl my-5 font-medium">
      {{ $t('tran25') }}
    </h1>
    <div class="flex px-2 mb-5">
      <img src="../assets/important-message.png" class="h-[1rem] mr-1 mt-2" />
      <p class="text-sm text-[#003794] mt-2 flex-grow text-left" v-html="$t('tran17')"></p>
    </div>
    <LoadingButton v-if="loading && !globalState.isRequestHistoryDone" />
    <div v-if="globalState.purchaseHistories.length" class="grid grid-row space-y-2">

      <div class="bg-white rounded-xl flex" v-for="history in globalState.purchaseHistories" :key="history.id"
        @click="$util.doCopy(history.code, callbackCopy)">

        <div class="h-full w-9 rounded-l-xl p-2 border-r border-dotted border-[#CACBCB]">
          <p class="text-[#CACBCB] text-xs ml-1 -rotate-90 h-[100px] w-[100px] text-center">ORD-{{
            String(history.id).padStart(6, '0') }}</p>
        </div>

        <div class="grow p-4">
          <div>
            <p class="text-base font-bold text-left mb-2">{{ history.title }}</p>
          </div>
          <div class="flex">
            <div class="grow content-left text-sm align-top space-y-2">
              <p class="pointer">
                {{ $t('tran41') }} :
                <span class="bg-[#0ABEEF]/50 px-2 py-0.5 rounded-md ml-5" v-if="history.status == 'PURCHASED'">
                  {{ history.code }}</span>
                <span class="bg-[#FF5938]/50 px-2 py-0.5 rounded-md ml-5" v-if="history.status == 'ACTIVATED'">
                  {{ history.code }}</span>
                <img src="../assets/copy.png" class="h-[16px] inline ml-2" />
              </p>
              <p> {{ $t('tran42') }} :<span class="ml-2">{{ history.createdAt }}</span></p>
            </div>
            <div class="flex-none py-2 align-top text-sm">
              <div class="text-[#0ABEEF] float-right space-y-2" v-if="history.status == 'PURCHASED'">
                <img src="../assets/blue.png" width="18" class="mb-2 -mt-2 mx-auto" />
                <p class="text-center"> {{ $t('tran44') }}</p>
              </div>
              <div class="text-[#FF5938] float-right space-y-2" v-if="history.status == 'ACTIVATED'">
                <img src="../assets/red.png" width="18" class="mb-2 -mt-2 mx-auto" />
                <p class="text-center"> {{ $t('tran43') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div v-if="!globalState.purchaseHistories.length &&
      !globalState.isRequestHistory &&
      !loading
      " class="bg-[#CBF1FF] py-10 px-4 text-center rounded-md">
      <div class="pb-4">{{ $t('tran30') }}</div>
      <router-link to="/" replace @click="globalState.setCurrentPage('/')">
        <button class="bg-[#48DAFE] rounded-md text-sm py-1 px-5">
          {{ $t('tran31') }}
        </button>
      </router-link>
    </div>
    <CopyToast v-if="isCopy" />
    <!-- <CopyToast /> -->
  </LayoutVue>
</template>

<script>
import { defineComponent } from 'vue';
import LayoutVue from '../components/LayoutApp.vue';
import LoadingButton from '../components/LoadingButton.vue';
import CopyToast from '../components/CopyToast.vue';
import { formatDate, hideScroll } from '../utils/helper.util';
import { globalState } from '../store';
import { getPurchaseHistoriesAsync } from '../services/purchase.service';

export default defineComponent({
  components: { LayoutVue, LoadingButton, CopyToast },
  data: () => {
    return {
      globalState,
      isHideText: true,
      loading: false,
      isCopy: false,
    };
  },
  watch: {
    visibleError(current) {
      hideScroll(current);
    },
    visibleExit(current) {
      hideScroll(current);
    },
    'globalState.profile': function (newVal) {
      this.getPurchaseHistories(newVal.id);
    },
  },
  mounted: function () {
    if (this.globalState.profile?.id) {
      this.getPurchaseHistories(this.globalState.profile?.id);
    }
    //this.getPurchaseHistories(1);
  },

  methods: {
    callbackCopy: function () {
      this.isCopy = false;
      this.isCopy = true;

      const timeout = setTimeout(() => {
        this.isCopy = false;
        clearTimeout(timeout);
      }, 2000);
    },
    onCloseModal: function () {
      this.visibleExit = false;
      this.visibleError = false;
    },
    getPurchaseHistories: async function (customerId) {
      try {
        this.loading = true;
        const purchases = await getPurchaseHistoriesAsync(customerId);
        const mapData = purchases.map((item) => {
          item.createdAt = formatDate(item.createdAt, 'en', 'DD/MM/YYYY');
          return item;
        });
        this.globalState.setPurchaseHistories(mapData);
        this.loading = false;

        if (mapData.length > 0) {
          this.globalState.setIsRequestHistory(true);
          this.globalState.setIsRequestHistoryDone(true);
        }
      } catch (err) {
        this.globalState.setVisibleError(true);
        this.loading = false;
      }
    },
  },
});
</script>
