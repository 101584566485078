import { reactive } from 'vue';

export const globalState = reactive({
  currentPage: '',
  plans: [],
  courses: [],
  purchaseHistories: [],
  loading: false,
  isRequestHistory: false,
  isRequestHistoryDone: false,
  isRequestPlan: false,
  isExitPage: false,
  isRequestPlanDone: false,
  profile: null,
  currentUser: null,
  defaultAccount: null,
  // defaultAccount: {
  //   accountNumber: '123456789',
  //   accountName: 'Saving Account For testing',
  //   currency: 'USD',
  // },
  currentPayment: null,
  coupon: null,
  showDebugInfo: '',
  increment() {
    this.count++;
  },
  visibleError: false,
  visibleExit: false,
  visibleDetailInfo: false,
  setCurrentPage: function (value) {
    this.currentPage = value;
  },
  setPurchaseHistories: function (value) {
    this.purchaseHistories = value;
  },
  setIsRequestHistoryDone: function (value) {
    this.isRequestHistoryDone = value;
  },
  setPlans: function (value) {
    this.plans = value;
  },
  setCourses: function (value) {
    this.courses = value;
  },
  setVisibleError: function (value) {
    this.visibleError = value;
  },
  setIsRequestPlanDone: function (value) {
    this.isRequestPlanDone = value;
  },
  setIsExitPage: function (value) {
    this.isExitPage = value;
  },
  setVisibleExit: function (value) {
    this.visibleExit = value;
  },
  setCurrentUser: function (user) {
    this.currentUser = user;
  },
  setProfile: function (profile) {
    this.profile = profile;
  },
  setDefaultAccount: function (account) {
    this.defaultAccount = account;
  },
  setCurrentPayment: function (payment) {
    this.currentPayment = payment;
  },
  setCoupon: function (coupon) {
    this.coupon = coupon;
  },
  setLoading: function (loading) {
    this.loading = loading;
  },
  setIsRequestHistory: function (data) {
    this.isRequestHistory = data;
  },
  setIsRequestPlan: function (data) {
    this.isRequestPlan = data;
  },
  setVisibleDetailInfo: function (value) {
    this.visibleDetailInfo = value;
  },
  setShowDebugInfo: function (value) {
    this.showDebugInfo = value;
  },
});
