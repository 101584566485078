<template>
  <div class="bg-[#EDF7FB] font-Hanuman overflow-hidden fixed top-0 right-0 left-0 bottom-0 z-[70] w-full h-full">
    <div class="grid grid-cols-1 px-10 relative h-full">
      <div class="my-auto text-center">
        <div class="mb-7">
          <img src="../assets/cat-hello.png" class="mx-auto w-[5rem]" />
        </div>
        <div class="mb-10">{{ $t('tran35') }}</div>
        <div>
          <button
            class="w-full text-black text-[1.1rem] text-bold bg-[#00D8D6] hover:bg-amber-500 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-md px-5 py-2.5 text-center"
            @click="handleCloseModal">
            {{ $t('tran36') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { globalState } from '../store';

export default defineComponent({
  data: function () {
    return { globalState };
  },
  methods: {
    handleCloseModal() {
      window.location.reload();
    },
  },
  mounted: function () {
    // this.globalState.setLoading(false);
  },
});
</script>
