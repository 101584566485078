const firebaseConfig = {
  apiKey: "AIzaSyCgaUQp0vqClo0SYLjltXJmEZXUaeRADLE",
  authDomain: "tesdopi3-b1d79.firebaseapp.com",
  databaseURL: "https://tesdopi3-b1d79-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "tesdopi3-b1d79",
  storageBucket: "tesdopi3-b1d79.appspot.com",
  messagingSenderId: "980834358454",
  appId: "1:980834358454:web:e5b3781e51dfc55fb5c217",
  measurementId: "G-W6KDTJKD2Y"
};


export const STORAGE_BASE_PATH = `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/@@@@@?alt=media`;

export const storagePath = (path) => {
  if (!path) return '';
  path.trim('/', '');
  return STORAGE_BASE_PATH.replace(
    '@@@@@',
    path.trim('/', '').replace('/', '%2F')
  );
};
