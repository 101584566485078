// import moment from 'moment/src/moment';
import dayjs from 'dayjs';
import { copyText } from 'vue3-clipboard';

export const hideScroll = (current) => {
  if (current) document.body.style.overflow = 'hidden';
  else document.body.style.overflow = 'inherit';
};

export const formatDate = (date, lang = 'kh', format = 'DD MMMM YYYY') => {
  if (lang === 'kh') {
    let months = {
      1: 'មករា',
      2: 'កុម្ភៈ',
      3: 'មីនា',
      4: 'មេសា',
      5: 'ឧសភា',
      6: 'មិថុនា',
      7: 'កក្កដា',
      8: 'សីហា',
      9: 'កញ្ញា',
      10: 'តុលា',
      11: 'វិច្ឆិកា',
      12: 'ធ្នូ',
    };

    return dayjs(date).format(
      `DD ខែ${months[dayjs(date).month() + 1]} ឆ្នាំ YYYY`
    );

  }

  return dayjs(date).format(format);
 
};

export const doCopy = (text, cb = null) => {
  copyText(`${text}`, undefined, (error, event) => {
    if (error) {
      alert('Can not copy');
      console.log(error);
    } else {
      console.log(event);
      if (cb) cb();
    }
  });
};

export const getWidth = () => {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
};

export const getHeight = () => {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );
};

console.log('Width:  ' + getWidth());
console.log('Height: ' + getHeight());
