<template>
  <LayoutVue>

    <div class="text-gray-600" style="font-family: 'Inter', sans-serif, 'Hanuman', serif;">

      <!-- គម្រោងព្រីមៀម -->
      <div>
        <h1 class="text-[#013795] text-2xl font-medium">{{ $t('tran3') }}</h1>
        <p class="text-xs my-3">{{ $t('tran4') }}</p>
      </div>
      <LoadingButton v-if="globalState.isRequestPlan" />

      <div class="grow grid grid-rows-2 gap-3">

        <div v-for="plan in globalState.plans" :key="plan.id" :style="{ 'background-color': plan.color }"
          class="p-4 content-center flex rounded-xl gap-4" @click="onShowModalPaymentPlan(plan)">
          <div class="bg-white/40 p-4 pt-5 w-[70px] h-[70px] rounded-[100px]">
            <img src="../assets/premium.png" class="float" />
          </div>
          <div class="grid-rows-2 flex-grow text-right">
            <p class="font-bold text-[#003794] text-md text-ellipsis overflow-hidden mb-2">
              {{ plan.title }}
            </p>
            <span class="text-xs mr-2 line-through decoration-slate-500" v-if="plan.discount > 0">
              ${{ plan.discount }}
            </span>
            <span class="text-white bg-[#F66988] px-4 py-1 text-sm rounded-md font-bold">
              ${{ plan.price }}
            </span>
          </div>
        </div>
      </div>


      <!-- វគ្គសិក្សាពិសេស -->
      <div>
        <h1 class="text-[#013795] text-2xl mt-5 font-medium">
          {{ $t('tran5') }}
        </h1>
        <p class="text-xs my-3 leading-5">
          {{ $t('tran6') }}
        </p>
      </div>
      <LoadingButton v-if="globalState.isRequestPlan" />
      <div class="grid gap-3">
        <div v-for="course in globalState.courses" :key="course.id" class="p-4 rounded-xl flex gap-4"
          :style="{ 'background-color': course.color }" @click="onShowModalPaymentCourse(course)">
          <div>
            <img :src="course.iconPath" class="w-[70px] mx-auto my-auto" />
          </div>
          <div class="w-full">
            <p class="text-[#013795] text-md font-bold">
              {{ course.title }}
            </p>
            <p class="text-xs">
              {{ course.subtitle }}
            </p>
            <div class="text-right mt-2">
              <span class="text-xs mr-2 line-through decoration-slate-500" v-if="course.discount > 0">
                ${{ course.discount }}
              </span>
              <span class="text-white bg-[#EF5350] px-4 py-1 text-[12px] rounded-md font-bold">
                ${{ course.price }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="visiblePayConfirm" class="fixed bg-[#FFF] left-0 bottom-0 z-[70] w-full p-[16px]"
      style="font-family: 'Inter', sans-serif, 'Hanuman', serif; display: inline-flex; flex-direction:column; align-items: flex-start; gap: 16px">

      <div v-if="isSelectAccount && globalState?.defaultAccount?.accountNumber"
        class="flex grow space-x-2 justify text-[#005D85] font-bold">
        <img src="../assets/abapay.png" class="w-[2rem] font-bold " />
        <p class="uppercase text-[#000]"
          style="font-style: normal; font-size: 14px; font-family: 'Inter', sans-serif, 'Hanuman', serif; font-weight:600; line-height: 18px">
          {{
            "aba pay" }}</p>
      </div>

      <!-- Account Info -->

      <div v-if="isSelectAccount && globalState?.defaultAccount?.accountNumber" class="w-full flex">
        <div class="flex grow justify-between" @click="onRequestPayment(false)">
          <div class="">
            <!-- Account Name -->
            <p class="text-ellipsis" style="color: #000; font-size:16px;
              font-family: 'Inter', sans-serif, 'Hanuman', serif;
              letter-spacing: -0.066px; font-weight: 600; line-height:22px; font-style:normal">
              {{ globalState?.defaultAccount?.accountName }}
            </p>
            <!-- Account Number -->
            <p
              style="opacity: 0.5; color: #000; font-size:12px; font-weight: 500; font-family: 'Inter', sans-serif, 'Hanuman', serif; font-style:normal;line-height:20px;">
              {{ globalState?.defaultAccount?.accountNumber.replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} I {{
                globalState?.defaultAccount?.currency }}
            </p>
          </div>
          <div class="flex grid-cols-2">
            <span
              style="color:#EF5350; font-family: 'Inter', sans-serif, 'Hanuman', serif; font-size: 16px; font-weight: 600">{{
                "Change"
              }}</span>
            <div class="w-[20px]"><svg class="mt-1 ml-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 16 16" fill="none">
                <g clip-path="url(#clip0_1155_2991)">
                  <path d="M13.3333 5.33329L7.99992 10.6666L2.66659 5.33329" stroke="#EF5350" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_1155_2991">
                    <rect width="16" height="16" fill="white" transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 16 16)" />
                  </clipPath>
                </defs>
              </svg></div>

          </div>

        </div>
      </div>

      <!-- Pay Button -->
      <button class="py-[13px] w-full h-[48px] bg-[#EF5350] rounded-[8px] shrink-0 mx-auto"
        @click="onRequestPayment(true)"
        style="text-align: right; font-style: normal; font-weight: 600; line-height: 22px">
        <p class="font-[16px] leading-[22px]"
          style="color: var(--White,#FFF); font-family: 'Inter', sans-serif, 'Hanuman', serif; text-align: center; line-height: 22px; letter-spacing: -0.066px ; font-weight:600; font-style:normal">
          {{ $t('tran12') }} ${{ globalState.currentPayment?.price.toFixed(2) }}
        </p>
      </button>

    </div>

    <BaseModal v-if="visiblePay" @handleCloseModal="onCloseModal">
      <div class="px-4 content-center relative w-full h-full grid justify-items-center">
        <div class="relative bg-white rounded-lg shadow mb-[70px]">
          <ButtonCloseVue @handleCloseModal="onCloseModal" />
          <div class="py-6 px-6 lg:px-8">
            <div class="-mt-[6rem] mb-10">
              <img src="../assets/cat-crown.png" class="mx-auto w-[7rem]" />
            </div>
            <div>
              <div class="flex mb-2">
                <div class="flex-none text-[#003794]">
                  <div class="w-[100px]">{{ $t('tran9') }}:</div>
                </div>
                <div class="grow text-right font-medium">
                  {{ globalState.currentPayment?.title }}
                </div>
              </div>
              <div class="flex">
                <div class="flex-none text-[#003794]">
                  <div class="w-[100px]">{{ $t('tran10') }}:</div>
                </div>
                <div class="grow text-right font-medium">
                  ${{ globalState.currentPayment?.price }}
                </div>
              </div>
            </div>
            <div class="flex justify-start my-6 space-x-4 content-center">
              <img src="../assets/important-message.png" class="h-[1.5rem] " />
              <p class="text-left text-sm">
                {{ $t('tran11') }}</p>
            </div>
          </div>

        </div>
      </div>
    </BaseModal>

    <PaymentSuccessModalVue v-if="visiblePaySuccess" @handleCloseModal="onCloseModal"
      @handleDownload="htmlToImageToPng" />
    <PaymentFailModalVue v-if="visiblePayFail" @handleCloseModal="onCloseModal" />
  </LayoutVue>
</template>

<script>
import { defineComponent } from 'vue';
import LayoutVue from '../components/LayoutApp.vue';
import PaymentFailModalVue from '../components/PaymentFailModal.vue';
import PaymentSuccessModalVue from '../components/PaymentSuccessModal.vue';
import { hideScroll } from '../utils/helper.util';
import BaseModal from '../components/BaseModal.vue';
import LoadingButton from '../components/LoadingButton.vue';
import ButtonCloseVue from '../components/ButtonClose.vue';
import { globalState } from '../store';
import {
  purchaseCouponAsync,
  getMiniAppProductList,
  logCreateAsync,
  getConsumerIdAsync,
} from '../services/purchase.service';
import { storagePath } from '@/utils/firebase';

export default defineComponent({
  components: {
    LayoutVue,
    PaymentFailModalVue,
    PaymentSuccessModalVue,
    BaseModal,
    ButtonCloseVue,
    LoadingButton,
  },
  data() {
    return {
      globalState,
      visiblePay: false,
      visiblePaySuccess: false,
      visiblePayFail: false,
      visiblePayConfirm: false,
      isSelectAccount: false,
    };
  },
  watch: {
    visibleError(current) {
      hideScroll(current);
    },
    visiblePay(current) {
      hideScroll(current);
    },
    'globalState.visibleExit': function (current) {
      hideScroll(current);
    },
    visiblePaySuccess(current) {
      hideScroll(current);
    },
    visiblePayFail(current) {
      hideScroll(current);
    },
  },
  mounted: function () {
    if (window.history.state?.forward === null || this.isEmpty(window.history.state)) {
      this.$router.push({ hash: "?detect-onpopstate=true" });
    }
    if (!this.globalState.isRequestPlanDone) {
      this.globalState.setIsRequestPlan(true);
      getMiniAppProductList()
        .then((res) => {
          this.globalState.setPlans(res.plans);
          const courses = res.courses.map((course) => {
            return {
              ...course,
              iconPath: storagePath(`images/${course.icon}.${course.iconType}`),
            };
          });
          this.globalState.setCourses(courses);
          this.globalState.setIsRequestPlan(false);
          this.globalState.setIsRequestPlanDone(true);
        })
        .catch((err) => {
          console.log(err.message);
          this.globalState.setIsRequestPlan(false);
        });
    }

    window.onpopstate = () => {
      console.log(window.history.state);
      if (window.history.state?.forward !== '/purchase-history' && window.history.state?.forward !== null && window.history.state?.forward !== '/?detect-modal=true') {
        this.globalState.setVisibleExit(true);
      } else {
        this.onCloseModal();
      }
    }

    this.$bridge.registerHandler('getStatus', (data) => {
      this.onCloseModal();
      let planId = '';
      let subscriptionCourseId = '';
      let purchaseType = this.globalState.currentPayment.type;

      if (purchaseType === this.$config.purchaseType.studyPlan) {
        planId = this.globalState.currentPayment.id;
      } else {
        subscriptionCourseId = this.globalState.currentPayment.id;
      }

      const { id, firstName, lastName, email, phone, sex } =
        this.globalState.profile;

      this.purchaseCoupon({
        platform: 'MINI APP',
        purchaseType,
        planId,
        subscriptionCourseId,
        transactionId: data.transactionId || data.trxId,
        customerId: id,
        customer: {
          id,
          fullName: firstName + ' ' + lastName,
          email,
          phone,
          sex,
        },
      });
    });

    if (
      this.globalState.visibleError ||
      this.globalState.visibleExit ||
      this.visiblePay ||
      this.visiblePayFail ||
      this.visiblePaySuccess
    )
      document.body.style.overflow = 'hidden';
  },
  methods: {
    htmlToImageToPng() {
      //
    },

    isEmpty: function (obj) {
      for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
          return false;
        }
      }
      return true;
    },

    formatAccount(data) {

      if (!data?.accountNumber) return '';

      let accountNumber = data.accountNumber + '';
      let accountNumberTrim = accountNumber.substring(
        accountNumber.length - 3,
        accountNumber.length
      );
      const length = 10;
      let accountName = data.accountName;
      let accountNameTrim = accountName.substring(0, length);

      return `${accountNameTrim}... : * ${accountNumberTrim} | ${data.currency}`;
    },
    onRequestOrder: function () {
      this.$bridge
        .callHandler('getDefaultAcc', {
          currency: 'USD',
          amount: globalState.currentPayment?.price + '',
        })
        .then((res) => {
          this.globalState.setDefaultAccount(res);
          this.visiblePayConfirm = true;

          if (res !== null && res !== undefined) {
            this.isSelectAccount = true;
          }
        })
        .catch((err) => {
          this.globalState.setShowDebugInfo(err.message);
          this.globalState.setVisibleError(true);
        });
    },
    onShowModalPaymentPlan: function (data) {
      this.$router.push({ hash: "?detect-modal=true" });
      this.globalState.setCurrentPayment({
        id: data.id,
        title: data.title,
        price: data.price,
        displayPrice: data.subtitle,
        type: this.$config.purchaseType.studyPlan,
      });
      this.visiblePay = true;
      this.onRequestOrder();
    },
    onShowModalPaymentCourse: function (data) {
      this.$router.push({ hash: "?detect-modal=true" });
      this.globalState.setCurrentPayment({
        id: data.id,
        title: data.title,
        price: data.price,
        displayPrice: data.displayPrice,
        type: this.$config.purchaseType.specialCourse,
      });
      this.visiblePay = true;
      this.onRequestOrder();
    },
    onCloseModal: function () {
      this.$router.push({ hash: "?detect-onpopstate=true" });
      this.visiblePay = false;
      this.visiblePaySuccess = false;
      this.visiblePayFail = false;
      this.globalState.setVisibleExit(false);
      this.globalState.setVisibleError(false);
      this.visiblePayConfirm = false;
      this.isSelectAccount = false;
    },
    onRequestPayment: async function (useDefault = false) {
      let isDefault = useDefault;
      if (!globalState?.defaultAccount?.accountNumber) {
        isDefault = false;
      }

      this.$bridge
        .callHandler('doPayment', {
          amount: globalState.currentPayment?.price + '',
          account: getConsumerIdAsync(),
          currency: 'USD',
          useDefault: isDefault,
        })
        .catch(() => {
          this.globalState.setVisibleError(true);
        });
    },
    onExit: function () {
      this.onCloseModal();
    },

    purchaseCoupon: async function (data) {
      this.$router.push({ hash: "?detect-modal=true" });
      try {
        this.globalState.setLoading(true);
        const coupon = await purchaseCouponAsync(data);

        this.globalState.setCoupon(coupon);
        this.visiblePaySuccess = true;
        this.globalState.setLoading(false);
      } catch (err) {
        this.globalState.setVisibleError(true);
        this.globalState.setLoading(false);
        logCreateAsync({
          context: 'HTTP',
          message: JSON.stringify(data),
          level: 'error',
        });

        logCreateAsync({
          context: 'HTTP',
          message: err.response.data.message,
          level: 'error',
        });
      }
    },
  },
});
</script>
