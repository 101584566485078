import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import PurchaseHistory from '../views/PurchaseHistory.vue';
import { routePath } from '../const/config';

const routes = [
  {
    path: routePath.home,
    name: 'home',
    component: HomeView,
  },
  {
    path: routePath.purchaseHistory,
    name: 'about',
    component: PurchaseHistory,
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory('/#/'),
  routes,
});

export default router;
