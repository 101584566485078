<template>
  <BaseModalVue @handleCloseModal="onCloseModal">
    <div class="bg-[#323F42] relative px-4 w-full h-full grid justify-items-center content-center pb-40 font-Hanuman">
      <div class="mb-6 -rotate-3">
        <img src="../assets/smile-cat.png" class="mx-auto w-[7rem] rotate-3" />
        <div class="-mt-3 px-16 py-2 bg-[#FFC02E] w-full rounded-3xl z-0">
          <p class="self-center z-0">{{ $t('tran45') }}</p>
        </div>
      </div>
      <div class="relative w-full bg-white rounded-lg p-2">
        <ButtonCloseVue @handleCloseModal="handleCloseModal" />
        <div class="bg-[#323F42] absolute top-[40%] cleft h-10"></div>
        <div class="bg-[#323F42] absolute top-[40%] cright h-10"></div>
        <div class="py-2 px-6 lg:px-8 text-center">
          <p class="font-bold text-[1.8rem] mb-4">
            {{ $t('tran16') }}
          </p>
          <div class="mx-2 pb-1 pt-2 rounded-md relative my-3 custom-coupon-bgx bg-[#F66988]" id="to-png"
            @click="$util.doCopy(globalState.coupon?.code, callbackCopy)">
            <span class="font-bold text-[1.8rem] text-white tracking-widest">
              {{ globalState.coupon?.code }}
            </span>
            <img src="../assets/copy.png" class="h-[24px] inline ml-1 mt-1 absolute" />
          </div>
          <p class="font-bold text-xs text-black tracking-wider">
            វគ្គសិក្សា : {{ globalState.coupon?.title || "Plan Title Plan Title Plan" }}
          </p>
          <div class="flex px-2">
            <img src="../assets/important-message.png" class="h-[1rem] mr-1 mt-2" />
            <p class="text-xs text-[#003794] mt-2 flex-grow text-left" v-html="$t('tran17')"></p>
          </div>
        </div>
      </div>

      <div class="w-full mt-10 bg-white p-4 absolute bottom-0 pt-4">
        <router-link to="/purchase-history" @click="handleCloseModal('/purchase-history')">
          <button
            class="w-full flex text-white text-bold text-[1rem] bg-[#F66988] font-medium rounded-sm text-md py-3 text-center items-center justify-center">
            {{ $t('tran24-1') }}
          </button>
        </router-link>
        <router-link to="/#" @click="handleCloseModal()">
          <button
            class="mt-3 w-full border border-[#F66988] flex text-[#F66988] text-bold text-[1rem] bg-white font-medium rounded-sm text-md py-3 text-center items-center justify-center">
            {{ $t('tran24-2') }}
          </button>
        </router-link>
      </div>

    </div>

  </BaseModalVue>
  <CopyToast v-if="isCopy" />
</template>

<script>
import { defineComponent } from 'vue';
import BaseModalVue from './BaseModal.vue';
import ButtonCloseVue from './ButtonClose.vue';
import CopyToast from './CopyToast.vue';
import { globalState } from '../store';
import { formatDate } from '../utils/helper.util';

export default defineComponent({
  components: {
    BaseModalVue,
    ButtonCloseVue,
    CopyToast,
  },
  data() {
    return { globalState, isCopy: false };
  },
  computed: {
    expiredDate: function () {
      return formatDate(
        this.globalState.coupon?.expireDate,
        // this.globalState.profile?.lang || 'kh'
        'kh'
      );
    },
  },
  methods: {
    callbackCopy: function () {
      this.isCopy = true;

      const timeout = setTimeout(() => {
        this.isCopy = false;
        clearTimeout(timeout);
      }, 2000);
    },
    handleCloseModal(path) {
      if (path === '/purchase-history') {
        console.log('no-detected');
      } else {
        this.$router.push({ hash: "?detect-onpopstate=true" });
        this.$emit('handleCloseModal');
      }
    },
  },
});
</script>
<style>
.custom-coupon-bg {
  background-image: url(../assets/bg-coupon.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.cleft {
  padding: 10px;
  width: 20px;
  height: 26px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  left: -5px;
}

.cright {
  padding: 10px;
  width: 20px;
  height: 26px;
  right: -5px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
</style>
