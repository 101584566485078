import en from './en.json';
import km from './km.json';

export const Locales = {
  EN: 'en',
  KM: 'kh',
};

export const messages = {
  [Locales.EN]: en,
  [Locales.KM]: km,
};

export const defaultLocale = Locales.KM;
