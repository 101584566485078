import { axios } from '@/utils/axios.util';
import moment from 'moment';

export const purchaseCouponAsync = async (data) => {
  return axios
    .post('/api/v1/payment-gateway/mini-app-purchase-coupon', data)
    .then((res) => res.data.data);
};

export const getPurchaseHistoriesAsync = async (customerId) => {
  return axios
    .get(
      `/api/v1/payment-gateway/mini-app-purchase-history/${customerId}?page=1&offset=100`
    )
    .then((res) => res.data.data);
};

export const getMiniAppProductList = async () => {
  return axios
    .get(`/api/v1/payment-gateway/mini-app-product-list`)
    .then((res) => res.data.data);
};

export const logCreateAsync = async (data) => {
  return axios.post('/logs/create', data).then((res) => res.data.data);
};

export const getConsumerIdAsync = () => {
  return moment().format('YYMMDDHHmmss');
};